video {
    position: relative;
    width: 100%;
    height: auto;
    margin-bottom: 30px;
    margin-top: 10px;
}

.second-section {
    max-width: 95%;
    margin-left: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.secondsection-text {
    width: fit-content;
    /* Or a specific width */
    margin-top: 20px;
    margin-bottom: 20px;
    text-align: center;
}

@media (max-width: 767px) {
    .button-for-mobile {
        height: 18vh;
    }
}