.bouton {
    position: absolute;
    right: calc(10vw);
    top: 10px;
}

.fix-div {
    gap: 3rem;
    flex-direction: column;
    justify-content: center;
    background-color: white
}

@media (min-width: 768px) {
    .fix-div {
        width: 80%;
        margin: 0 auto;
        display: flex;
    }
}

@media (max-width: 767px) {
    .img-container {
        display: block;
        gap: 15rem;
        max-width: 90%;
        margin-top: 50px;
    }
}

@media (min-width: 768px) {
    .img-container {
        display: block;
        gap: 15rem;
        max-width: 80%;
    }
}