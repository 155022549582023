.main-table {
    margin-top: 20px;
    display: block;
    overflow-x: scroll;
    max-height: 600px;
    overflow-y: scroll;
}

th {
    min-width: 150px;
}

.spinner-bis {
    display: flex;
    justify-content: center;
    align-items: center;
}

.table-overflow {
    overflow-x: scroll;
    width: calc(100vw - 100px);
}
