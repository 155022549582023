.header-container {
    z-index: 999;
}

.sticky {
    position: fixed !important;
    top: 0;
    width: 100%
}

.sticky + .content {
    padding-top: 102px;
}