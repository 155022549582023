@media (min-width: 768px) {
    .container {
        width: 960px !important;
        display: flex;
        margin-bottom: 10%;
    }
}

.container {
    display: flex;
    flex: 1;
}

.check-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 3rem;
}

@media (max-width: 767px) {
    .container {
        width: 100% !important;
        display: flex;
        margin-bottom: 30%;
    }
}