.container-bis {
    display: flex;
    flex-direction: row; /* This keeps the Sidebar next to the content */
}

.content-container {
    width: 100px;
    display: flex;
    flex-direction: column; /* This ensures the h1 and table stack vertically */
    flex-grow: 1; /* This allows the content-container to take up the remaining space next to the Sidebar */
    gap: 2rem;
}

h1.title {
    margin-top: 20px;
    margin-bottom: 20px;
}

.main-row {
    width: 100%;
}