
.main-image {
    position: relative;
    max-width: 100%;
    margin-bottom: 30px;
    margin-top: 10px;
}

.first-section {
    max-width: 95%;
    margin-left: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.firstsection-text {
    width: fit-content;
    /* Or a specific width */
    margin-top: 20px;
    margin-bottom: 20px;
    text-align: center;
}