@media (min-width: 768px) {
    .name {
        display: flex;
        gap: 1rem;
        flex: 1 0 calc(33%);
    }
}

@media (max-width: 767px) {
    .name {
        display: flex;
        flex-direction: column;
        flex: 1;
    }

    .name .name-box {
        flex: none;
    }

}

.name .name-box {
    min-width: 0;
    /* Prevent overflow caused by long content */
}

.container {
    background-color: white;
}

@media (min-width: 768px) {
    .container {
        width: 960px !important;
        display: flex;
        margin-bottom: 10%;
    }
}


.form {
    flex: 1;
}