.footer-container {
    min-height: 15vh;
    background: linear-gradient(to top, #fffaed 99%, white 100%);
    overflow: hidden;
    margin-top: auto;
}

.copyright {
    padding-top: 30px;
    text-align: center;
    padding-bottom: 15px;
}

.social-icons {
    display: flex;
    justify-content: center;
    gap: 1rem;
}