.panne {
    display: block;
    justify-content: center;
    align-items: center;
}

.gap-2 {
    gap: .2rem !important;
}

.mb-3 {
    margin-bottom: 1rem !important;
}

.d-flex {
    display: flex !important;
}

.sessions-max {
    overflow: hidden;
}

@media (max-width: 767px) {
    .table-overflow {
        overflow-x: scroll;
        width: calc(100vw - 100px);
    }
}